import { Col, Container, Nav, Row } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import React from "react";
import logowhite from "../assets/img/logo-white.svg";

const Footer = () => {
  return (
    <footer>
      <div className="footertop">
        <Container>
          <Row>
            <Col md={5} xl={4}>
              <div className="footer_info">
                <img className="footer_logo" src={logowhite} alt="" />
                <p>
                  The Fractional Coach, with over 20 years of experience
                  advising clients and talent and building world-class
                  leadership teams, can provide you with real-time answers to
                  all your pressing career questions.
                </p>
              </div>
            </Col>
            <Col md={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 4 }}>
              <div className="footer_nav mt_115">
                <h3>Quick Links</h3>
                <Nav as="ul">
                  <Nav.Item as="li">
                    <Nav.Link as={NavLink} to="/#home">
                      Home
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link as={NavLink} to="/#about">
                      About
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link as={NavLink} to="/#how_it_works">
                      How It Works
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      as={NavLink}
                      to={"mailto:webmaster@thefractionalcoach.co"}
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div className="copy_right">
          <p>©Copyright 2024. All Rights Reserved.</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
