import "./assets/css/style.scss";
import "react-activity/dist/library.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React, { useEffect, useState } from "react";

import Home from "./screens/Home";
import Layout from "./Layout";
import ScrollToAnchor from "./utils/ScrollToAnchor";
import loaderimg from "./assets/img/loader.svg";
function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <img src={loaderimg} className="img-fluid" alt="" />
        </div>
      ) : (
        <Router basename={"/"}>
          <ScrollToAnchor />
          <Routes>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route path="/" element={<Home />} />
            </Route>
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
