import { Container, Nav, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.svg";

const Header = ({ title }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const closeNav = () => setExpanded(false);
  const [scrolled, setScrolled] = useState(false);

  // Add event listener to detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let headerClass = scrolled ? "header header_fixed" : "header";
  return (
    <Navbar className="header header_fixed" collapseOnSelect expand="lg">
      <Container className="main_container">
        <Navbar.Brand href="">
          <img className="header_logo" src={logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleToggle}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto navbar_menu">
            <Nav.Link as={NavLink} to="/#home" onClick={closeNav}>
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/#about">
              About Us
            </Nav.Link>
            <Nav.Link as={NavLink} to="/#how_it_works">
              How It Works
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to={"mailto:webmaster@thefractionalcoach.co"}
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
